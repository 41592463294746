import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../components/button';
import {WillAnimate} from '../../components/content_animate';
import {Cta} from '../../components/cta';
import {Layout} from '../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Ons portfolio van softwareproducten"
        description="Verschillende voorbeelden van ons werk. Hier een overzicht van webplatformen, verrijkende native apps en SaaS-producten die wij ontwikkelden voor onze opdrachtgevers."
        ogImage="portfolio.png"
        translatedPagePath="/en/portfolio/">
        <section className="content content--no-hero">
            <article className="container">
                <Link
                    to="/nl/portfolio/saas-product-keeping"
                    className="case-item-wrapper"
                    title="Case: Ons eigen product: Keeping">
                    <WillAnimate as="div" className="case-item case-item--odd">
                        <div className="case-item__image-wrapper">
                            <Img alt="Keeping" fluid={data.keepingBarend.childImageSharp.fluid} />
                        </div>
                        <div className="case-item__content">
                            <div className="case-item__title">
                                <h2>Ons eigen SaaS-product: Keeping</h2>
                            </div>
                            <div className="case-item__subtitle">
                                <h3>Moeiteloze tijdregistratie. Elk moment van de dag.</h3>
                            </div>
                            <Button name="Bekijk deze case" />
                        </div>
                    </WillAnimate>
                </Link>
            </article>

            <article className="container">
                <Link
                    to="/nl/portfolio/vertaalplatform-fairlingo/"
                    className="case-item-wrapper"
                    title="Case: Vertaalplatform Fairlingo">
                    <WillAnimate as="div" className="case-item case-item--even">
                        <div className="case-item__image-wrapper">
                            <Img alt="Fairlingo" fluid={data.fairlingo.childImageSharp.fluid} />
                        </div>
                        <div className="case-item__content">
                            <div className="case-item__title">
                                <h2>Vertaalplatform Fairlingo</h2>
                            </div>
                            <div className="case-item__subtitle">
                                <h3>De Uber voor vertalers.</h3>
                            </div>
                            <Button name="Bekijk deze case" />
                        </div>
                    </WillAnimate>
                </Link>
            </article>

            <article className="container">
                <Link
                    to="/nl/portfolio/taxatieplatform-taxapi/"
                    className="case-item-wrapper"
                    title="Case: Taxatieplatform TaXapi">
                    <WillAnimate as="div" className="case-item case-item--odd">
                        <div className="case-item__image-wrapper">
                            <Img alt="Taxatieplatform TaXapi in gebruik" fluid={data.taxapi.childImageSharp.fluid} />
                        </div>
                        <div className="case-item__content">
                            <div className="case-item__title">
                                <h2>Taxatieplatform TaXapi</h2>
                            </div>
                            <div className="case-item__subtitle">
                                <h3>Alles voor je taxaties binnen handbereik.</h3>
                            </div>

                            <Button name="Bekijk deze case" />
                        </div>
                    </WillAnimate>
                </Link>
            </article>
        </section>

        <Cta image={data.olav.childImageSharp.fixed} />
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
        keepingBarend: file(relativePath: {eq: "portfolio/keeping/keeping-barend.jpg"}) {
            ...fluid1200Width
        }
        taxapi: file(relativePath: {eq: "portfolio/taxapi/taxapi-main.jpg"}) {
            ...fluid1200Width
        }
        fairlingo: file(relativePath: {eq: "portfolio/fairlingo/fairlingo.jpg"}) {
            ...fluid1200Width
        }
    }
`;
